<template>
    <saffron class="app-external-wrapper animate__fadeIn" :use-meta="true" ></saffron>
</template>

<script>
import SaffronComponent from '@/client/components/Saffron.vue';

export default {
    components: {
        Saffron: SaffronComponent
    },
    props : {
        layout : {
            type: String,
            default: 'default'
        },
        forcedLayout : {
            default: false,
            validator: (value) => (typeof value === 'string' || value === false)
       }
    },
    data() {
        return {

        }
    },
    mounted() {

    }
  }
</script>
<style lang="scss" scoped>
.global-app-wrapper {
    display: flex;
    flex-direction: column;
    flex: 100vh;
    min-height: 100vh;
}

</style>
