<template>
  <div class="saffron-app" :dir="isPageRtl ? 'rtl' : 'ltr'" v-bind="$attrs">
    <!-- bar loader service -->
    <bar-loader
      v-if="useAsyncLoadBar"
      class="z-level-top-2"
      :active="asyncStatus.anyLoading"
      :fixed="true"
    ></bar-loader>
    <!-- meta service -->
    <metainfo>
      <template v-slot:title="input">
        {{
          input.content
            ? input.content + " | " + input.metainfo.sitename.content
            : input.metainfo.sitename.content
        }}
      </template>
    </metainfo>

    <suspense>
      <div class="saffron-app-inner" v-if="useLayouts">
        <component :is="finalLayout" v-bind="layoutParams">
          <slot>
            <router-view v-slot="{ Component }" :key="$route.fullPath">
              <transition
                mode="out-in"
                :enter-active-class="routerTransitionClassIn"
                :leave-active-class="routerTransitionClassOut"
              >
                <component :is="Component" class="" v-if="Component" />
              </transition>
            </router-view>
          </slot>
        </component>
      </div>
      <template v-slot:fallback="">
        <spinner :overlay="'fixed'"></spinner>
      </template>
    </suspense>
    <suspense>
      <div class="saffron-app-inner" v-if="!useLayouts">
        <slot>
          <router-view v-slot="{ Component }" :key="$route.fullPath">
            <transition
              mode="out-in"
              :enter-active-class="routerTransitionClassIn"
              :leave-active-class="routerTransitionClassOut"
            >
              <component
                :is="Component"
                class="animate__fadeIn"
                v-if="Component"
              />
            </transition>
          </router-view>
        </slot>
      </div>
      <template v-slot:fallback="">
        <spinner :overlay="'fixed'"></spinner>
      </template>
    </suspense>
  </div>

  <inactivity-disconnect></inactivity-disconnect>
  <!-- global spinner service -->
  <spinner
    class="z-level-top-2"
    v-if="useGlobalSpinner"
    overlay="fixed"
    :text-params="globalSpinnerTextParams"
    :show="globalSpinnerActive"
    :text="globalSpinnerText"></spinner>

  <teleport to="body">
    <modal
      ref="authErrorModal"
      :title="translate('core.user.clientSideAuthErrorTitle')"
      :show="showDisconnectedModal">
      <template #default>
        {{ translate("core.user.clientSideAuthErrorExplain") }}
      </template>
      <template #footer>
        <form-button @click="acceptDisconnect"
        >{{ translate("core.user.clientSideAuthErrorOk") }}
        </form-button>
      </template>
    </modal>

    <modal
      v-for="conf in modals"
      @modal:afterClose="
        modalStackCloseHandler({
          uid: conf.uid,
          actionType: $event.context || 'reject',
          data: conf.promptValue,
        })"
      :key="conf.uid"
      :closeButton="conf.type !== 'confirm'"
      :easyClose="conf.type !== 'confirm'"
      :ref="`modal${conf.uid}`"
      :show="true"
      :autoTranslate="false"
      :title="conf.title">
      <template>
        <div class="" v-html="conf.content"></div>
        <div
          class="margin-l-bottom"
          v-if="conf.content && conf.content !== '' && conf.type === 'prompt'"
        ></div>
        <div class="" v-if="conf.type === 'prompt'">
          <form-input
            v-bind="conf.options.field"
            v-model="conf.promptValue"
          ></form-input>
        </div>
      </template>
      <!-- TODO: make confirm "lock" and not allow closing with screen click -->
      <template v-slot:footer="{ modal }">
        <div class="flex flex-end gap-m">
          <!-- case of - alert todo: handle type -->
          <form-button
            v-if="conf.type === 'confirm' || conf.type === 'prompt'"
            theme="gray-5"
            icon="x"
            @click="modal.close('reject')"
          >{{ conf.options.labels.cancel }}
          </form-button>
          <form-button
            theme="success"
            icon-end="chevron-inline-end"
            @click="modal.close('fulfil')"
          >
            {{ conf.options.labels.ok }}
          </form-button>
        </div>
      </template>
    </modal>
  </teleport>
  <notification
    v-for="(conf, id) of notifications"
    v-bind="getSafeNotificationConfig(conf)"
    :key="id"
    @notification:afterClose="notificationClosedHandler(id)"
  ></notification>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import layoutComposition from "@/client/extensions/composition/layout";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { useMeta } from "vue-meta";
import { mapGetters } from "vuex";
import { watchEffect, computed } from "vue";

export default {
  setup(props) {
    let result = {};

    let { asyncOps, asyncStatus } = asyncOperations();
    result = { ...result, asyncOps, asyncStatus };

    // meta handling
    if (props.useMeta) {
      let { t } = useI18n();
      let { meta } = useMeta({
        title: t("core.meta.defaultTitle"),
        //  htmlAttrs: { lang: 'en', amp: true }, // now working. fuck third party
        description: t("core.meta.defaultDescription"),
        sitename: {
          tag: "meta",
          name: "sitename",
          content: t("core.meta.siteName")
        },
        charset: { tag: "meta", charset: "utf8" },
        generator: { tag: "meta", content: "Saffron - spice for your code" },

        "theme-color": {
          tag: "meta",
          name: "theme-color",
          content: config.themeColor
        }
      });
      result = { ...result, metaManager: meta };
    }

    if (props.useLayouts) {
      let { finalLayout, finalLayoutRaw, layoutParams } = layoutComposition(
        props,
        useRouter()
      );
      result = { ...result, finalLayout, finalLayoutRaw, layoutParams };
    }

    return result;
  },
  props: {
    useLayouts: {
      type: Boolean,
      default: true
    },
    useAsyncLoadBar: {
      type: Boolean,
      default: true
    },
    useGlobalSpinner: {
      type: Boolean,
      default: true
    },
    useMeta: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      isPageRtl: computed(() => {
        return this.isLanguageRtl();
      }),
      shortLocaleTag: computed(() => {
        return this.getLocaleShort();
      }),
      showDisconnectedModal: false,
      initialUserFetchComplete:
        this.$store.getters["user/initialFetchComplete"],
      modals: {},
      notifications: {},
      enableRouteTransitionAnimation: true,
      lastErrorInstance: false
    };
  },
  provide() {
    return {
      $saffronComponent: this,
      windowEvents: {
        on(event, callback) {
          if (utilities.isSSR()) {
            return this;
          }
          window.addEventListener(event, callback);
          return this;
        },
        off(event, callback) {
          if (utilities.isSSR()) {
            return this;
          }
          window.removeEventListener(event, callback);
        }
      },
      $cookie: this.$cookie
    };
  },
  computed: {
    routerTransitionClassIn() {
      return this.enableRouteTransitionAnimation
        ? config.style.pageTransitionClassIn
        : "";
    },
    routerTransitionClassOut() {
      return this.enableRouteTransitionAnimation
        ? config.style.pageTransitionClassOut
        : "";
    },
    layoutTransitionClassIn() {
      return this.enableRouteTransitionAnimation
        ? config.style.pageTransitionClassIn
        : "";
    },
    layoutTransitionClassOut() {
      return this.enableRouteTransitionAnimation
        ? config.style.pageTransitionClassOut
        : "";
    },
    ...mapGetters({
      globalSpinnerActive: "ui/isGlobalSpinnerActive",
      globalSpinnerText: "ui/globalSpinnerText",
      globalSpinnerTextParams: "ui/globalSpinnerTextParams",
      userAuthError: "user/authError"
    })
  },
  methods: {
    registerModal(conf) {
      // parse conf
      let uid = utilities.getUniqueNumber();

      this.modals[uid] = {
        uid: uid,
        type: conf.options.type || "alert",
        promptValue: conf.options.initialValue || null,
        ...conf
      };
    },
    modalStackCloseHandler(arg) {
      let modalConf = this.modals[arg.uid];

      if (!modalConf) {
        return;
      }

      // try to fulfil or reject the modal promise, if available
      if (arg.actionType === "fulfil" && modalConf.fulfil) {
        modalConf.fulfil(arg.data);
      }

      if (arg.actionType === "reject" && modalConf.reject) {
        modalConf.reject();
      }

      delete this.modals[arg.uid];
    },
    registerNotification(options) {
      let id = utilities.getUniqueNumber();
      options.ref = "notification-" + id;
      this.notifications[id] = { ...options, id };
    },
    notificationClosedHandler(id) {
      if (!this.notifications[id]) {
        return;
      }

      if (
        this.notifications[id].fulfil &&
        typeof this.notifications[id].fulfil === "function"
      ) {
        this.notifications[id].fulfil();
      }

      delete this.notifications[id];
    },
    closeAllNotifications() {
      for (const [conf] of Object.values(this.notifications)) {
        // case of array ref
        if (
          conf.ref &&
          this.$refs[conf.ref] &&
          Array.isArray(this.$refs[conf.ref]) &&
          this.$refs[conf.ref].length === 1
        ) {
          this.$refs[conf.ref][0].close();
        }

        // case of "regular" ref. I do not know if this can actually happen, tbh.
        if (
          conf.ref &&
          this.$refs[conf.ref] &&
          !Array.isArray(this.$refs[conf.ref])
        ) {
          this.$refs[conf.ref].close();
        }
      }
    },
    acceptDisconnect() {
      this.showDisconnectedModal = false;
      this.$router.go();
    },
    getSafeNotificationConfig(conf) {
      let result = { ...conf };

      ["id", "fulfil", "reject"].forEach((key) => {
        if (u.hasProperty(conf, key)) {
          delete result[key];
        }
      });
      return result;
    },
    redirectToErrorPage(errorInformation = false, errorAsString = false) {
      this.$router.push({
        name: "error",
        params: {
          technicalInformationHtml: errorInformation,
          errorAsString: errorAsString
        }
      });
    },
    informAboutError() {
      this.$s.ui.notification("core.errorCatchNotification", "error");
    }
  },
  serverPrefetch() {
    return new Promise((resolve) => {
      // when we get user prefetch - release this hook
      watchEffect(() => {
        if (this.$store.getters["user/initialFetchComplete"]) {
          resolve();
        }
      });
    });
  },
  async created() {
    // provide self to saffron framework
    if (!this.$root.$saffronComponent) {
      this.$root.$saffronComponent = this;
    }

    this.setSaffronComponent(this);

    if (!config.useDefaultSocketFunctionality || utilities.isSSR()) {
      return true;
    }

    // listen to unexpected disconnects - refresh token revocations
    let socket = await this.asyncOps.socket.init();
    await socket.waitForUUID();

    socket.on("refreshTokensRevokedRemotely", (data) => {
      let ourToken = this.$store.getters["user/refreshToken"];
      if (data.deletedTokens.includes(ourToken)) {
        this.$s.ui.notification(
          "core.user.remoteRefreshTokenRevocationNotification",
          "warning"
        );
        this.$store.commit("user/logout");
        this.$router.push(config.user.saffronUser.defaultLogoutRedirect);
      }
    });
  },
  watch: {
    userAuthError(newVal, oldVal) {
      // on auth error, show modal and logout the user. error is considered handled
      if (newVal && !oldVal) {
        this.$store.commit("user/setAuthError", false);
        this.$store.commit("user/logout");

        if ( ! config.saffronUser.newSessionReconnectSilentErrors) {
          this.showDisconnectedModal = true;
        }
      }
    },
    isPageRtl: {
      handler() {
        if (utilities.isSSR()) {
          return;
        }

        if (this.isLanguageRtl()) {
          document.querySelector("html").setAttribute("dir", "rtl");
        } else {
          document.querySelector("html").setAttribute("dir", "ltr");
        }
      },
      immediate: true
    },
    shortLocaleTag: {
      handler() {
        if (utilities.isSSR()) {
          return;
        }

        document
          .querySelector("html")
          .setAttribute("lang", this.shortLocaleTag);
      },
      immediate: true
    }
  },
  updated() {
  },
  mounted() {
  },
  errorCaptured(error, instance, info) {
    const name = instance.$options.name;
    const errorAsString = error.toString();
    const isRenderError = info === "render function";
    let errorInformationString = "";
    let errorStringName = name ? name : "unknown name";
    // make error information

    // should redirect: redirect if error is in page or is a render error
    errorInformationString += `Message: ${error.message}<br>`;
    errorInformationString += `Hook information: ${info}<br>`;
    errorInformationString += `In: ${errorStringName}<br>`;
    warn("saffron cought an error", 1, error);
    utilities.safeLog(error);
    if (isRenderError) {
      this.redirectToErrorPage(errorInformationString, errorAsString);
    } else {
      this.informAboutError(error.message);
    }

    return false;
  }
};
</script>

<style scoped lang="scss"></style>

<style lang="scss">
#notifications {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  width: auto;
  height: 0;
  overflow: visible;
}

#snackbar {
  position: fixed;
  bottom: 0;
  inset-inline-start: 0;
  height: auto;
  width: auto;
  max-width: 700px;
  overflow: visible;
  padding-inline-start: clamp(
    10px,
    var(--margin-m),
    var(--margin-m)
  ); // support weUI with default fallback fallback

  @media screen and (max-width: 800px) {
    max-width: 90vw;
  }
}
</style>
